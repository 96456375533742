import React from "react";
import AdminLogin from "../../../Components/Admin/Login/login";

function login() {
  return (
    <div>
      <AdminLogin />
    </div>
  );
}

export default login;
